import { MediaMatcher } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
  private mediaMatcher: MediaMatcher = inject(MediaMatcher);
  private drawer!: MatDrawer;
  public queryUnder1200!: MediaQueryList;

  public initResponsiveListener() {
    const limit = '(max-width: 1200px)';
    this.queryUnder1200 = this.mediaMatcher.matchMedia(limit);
  }

  public get over1200(): boolean {
    return !this.queryUnder1200?.matches;
  }

  public setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }

  public toggleDrawer() {
    this.drawer.toggle();
  }

  public closeDrawer() {
    this.drawer.close();
  }
}
