@if (userProfileChecked && response && translitTriggered) {

<mat-drawer-container>
  <mat-drawer #drawer
    [mode]="over1200 ? 'side' : 'over'"
    [opened]="over1200">
    <app-drawer-sidebar />
  </mat-drawer>
  <mat-drawer-content>
    <app-drawer-content />
  </mat-drawer-content>

</mat-drawer-container>

} @else {
<div id="splash-screen">
  @if (themeService.isLightMode()) {
  <img loading="lazy"
    src="assets/images/gallery-logo-light-cyrillic.png"
    alt="Logo Galerija" />
  } @else {
  <img loading="lazy"
    src="assets/images/gallery-logo-dark-cyrillic.png"
    alt="Logo Galerija" />
  }

  <mat-spinner></mat-spinner>
</div>
}