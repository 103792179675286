import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { ThemeService } from '@app/services/theme.service';

export interface MiniBox {
  style: string,
  imagePath: string,
  title: string,
  description?: string,
  link: string,
  icon: string | null,
  background: string,
}

@Component({
  selector: 'app-mini-box',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslitPipe],
  templateUrl: './mini-box.component.html',
  styleUrl: './mini-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniBoxComponent {
  @Input({ required: true }) box!: MiniBox;
  @Input({ required: false }) invertColor = false;
  @Input({ required: false }) textOver = false;
  @Output() clickEmitter = new EventEmitter<string>();
  public themeService = inject(ThemeService);

  onEmit(event: any) {
    this.clickEmitter.emit(event);
  }

}
