@if (box) {
<div class="scroll-box"
  [ngClass]="box.style"
  [class.text-over]="textOver"
  (click)="onEmit(box.link)"
  [style.backgroundImage]="box.background">
  @if (box.icon) {
  <mat-icon class="scroll-item-icon">
    {{box.icon}}
  </mat-icon>
  }
  @if (box.imagePath) {
  <img class="image"
    [class.invert-color]="invertColor && themeService.isDarkMode()"
    [src]="box.imagePath"
    [alt]="box.title | translit">
  }
  <span class="scroll-item-title">
    {{box.title | translit}}
  </span>
</div>
}