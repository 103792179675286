<div class="stats"
  [ngClass]="{'stick-to-side': stickToSide(),
  'borderless': borderless()}">
  <div class="counter"
    (click)="onPersonCounter()">
    <b class="auto-counter counter-1"
      [style.--num]="personCounter"></b>
    <span>{{'Личности' | translit}}</span>
  </div>

  <div class="counter"
    (click)="onHeritageCounter()">
    <b class="auto-counter counter-3"
      [style.--num]="heritageCounter"></b>
    <span>{{'Знаменитости'| translit}}</span>
  </div>

  <div class="counter"
    (click)="onQuotesCounter()">
    <b class="auto-counter counter-2"
      [style.--num]="quoteCounter"></b>
    <span>{{'Цитатa'| translit}}</span>
  </div>

  <div class="counter"
    (click)="onChronologyCounter()">
    <b class="auto-counter counter-4"
      [style.--num]="chronologyCounter"></b>
    <span>{{'Догађаја'| translit}}</span>
  </div>

</div>