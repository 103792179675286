import * as hammer from 'hammerjs';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ImageCropperModule } from 'ngx-image-cropper';
import { provideQuillConfig, QuillConfig, QuillToolbarConfig } from 'ngx-quill';
import { retry } from 'rxjs';
import { environment } from 'src/environments/environment';

import {
  HttpHandlerFn, HttpInterceptorFn, HttpRequest, provideHttpClient, withFetch, withInterceptors
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  InMemoryScrollingOptions, NoPreloading, provideRouter, RouterConfigOptions, withInMemoryScrolling,
  withPreloading, withRouterConfig, withViewTransitions
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';

/* import * as Sentry from '@sentry/angular-ivy'; */
import { routes } from './app.routes';

const firebaseConfig: any = environment.firebaseConfig;

export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: { direction: hammer.DIRECTION_ALL },
    pinch: { enable: true },
    rotate: { enable: false }
  };
}

const quillToolbar: QuillToolbarConfig =
  [['bold'], ['link'], [{ color: [] }], [{ align: [] }]];

const quillConfig: QuillConfig = {
  modules: { syntax: false, toolbar: { container: quillToolbar } }
};

const retryInterceptor = (retryTimes: number): HttpInterceptorFn => {
  return (request: HttpRequest<any>, next: HttpHandlerFn) => {
    const cloneRequest = request.clone();

    return next(cloneRequest).pipe(retry(retryTimes));
  };
};

const routerConfig: RouterConfigOptions =
  { onSameUrlNavigation: 'reload' };

const routerScrolling: InMemoryScrollingOptions =
  { scrollPositionRestoration: 'enabled' };

const workerName = 'ngsw-worker.js';

const workerConfig = {
  registrationStrategy: 'registerWhenStable:30000',
  enabled: !isDevMode(),
};

export const appConfig: ApplicationConfig = {
  providers: [
    /* provideExperimentalZonelessChangeDetection(), */
    provideHttpClient(
      withFetch(),
      withInterceptors([retryInterceptor(2)])
    ),
    provideServiceWorker(workerName, workerConfig),
    provideAnimations(),
    provideQuillConfig(quillConfig),

    provideRouter(routes,
      withPreloading(NoPreloading),
      withRouterConfig(routerConfig),
      withInMemoryScrolling(routerScrolling),
      withViewTransitions(),
    ),

    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),

    importProvidersFrom(
      NgxGoogleAnalyticsModule.forRoot(environment.gtag),
      NgxGoogleAnalyticsRouterModule,
      HammerModule,
      ImageCropperModule,
    ),

    /*     {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({ showDialog: false }),
        },
    
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
    
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => { },
          deps: [Sentry.TraceService],
          multi: true,
        }, */
  ],
};
