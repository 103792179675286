import { Route } from '@angular/router';
import { WelcomePageComponent } from '@app/pages/welcome-page/welcome-page.component';

import { adminGuard } from './guards/admin.guard';

const userRoutes: Route[] = [
  { path: 'nalog', loadComponent: () => import('./pages/account-page/account-page.component').then(mod => mod.AccountPageComponent) },
  { path: 'kontakt', loadComponent: () => import('./pages/contact-page/contact-page.component').then(mod => mod.ContactPageComponent) },
  { path: 'biografija/:slug', loadComponent: () => import('./pages/person-page/person-page.component').then(mod => mod.PersonPageComponent) },
  { path: 'kategorija/:slug', loadComponent: () => import('./pages/category-page/category-page.component').then(mod => mod.CategoryPageComponent) },
  { path: 'kategorije', loadComponent: () => import('./pages/category-picker-page/category-picker-page.component').then(mod => mod.CategoryPickerPageComponent) },
  { path: 'kviz', loadComponent: () => import('./pages/quiz-page/quiz-page.component').then(mod => mod.QuizPageComponent) },
  { path: 'kalendar/:date', loadComponent: () => import('./pages/calendar-page/calendar-page.component').then(mod => mod.CalendarPageComponent) },
  { path: 'srpsko-nasledje', loadComponent: () => import('./pages/heritage-page/heritage-page.component').then(mod => mod.HeritagePageComponent) },
  { path: 'srpsko-nasledje/:slug', loadComponent: () => import('./pages/heritage-page/heritage-page.component').then(mod => mod.HeritagePageComponent) },
  { path: 'srpsko-nasledje/:slug/:option', loadComponent: () => import('./pages/heritage-page/heritage-page.component').then(mod => mod.HeritagePageComponent) },
  { path: 'vremeplov', loadComponent: () => import('./pages/chronology-page/chronology-page.component').then(mod => mod.ChronologyPageComponent) },
  { path: 'vremeplov/:slug', loadComponent: () => import('./pages/chronology-page/chronology-page.component').then(mod => mod.ChronologyPageComponent) },
  { path: 'citati', loadComponent: () => import('./pages/quote-page/quote-page.component').then(mod => mod.QuotePageComponent) },
  { path: 'instagram', loadComponent: () => import('./pages/instagram-page/instagram-page.component').then(mod => mod.InstagramPageComponent) },
  { path: 'instagram/#:link', loadComponent: () => import('./pages/instagram-page/instagram-page.component').then(mod => mod.InstagramPageComponent) },
  { path: 'favoriti', loadComponent: () => import('./pages/user-favourites/user-favourites.component').then(mod => mod.UserFavouritesComponent) },
  { path: 'tagovi', loadComponent: () => import('./pages/tags-page/tags-page.component').then(mod => mod.TagsPageComponent) },
  { path: 'tag/:slug/:tab', loadComponent: () => import('./pages/tag-results-page/tag-results-page.component').then(mod => mod.TagResultsPageComponent) },
  { path: 'tag/:slug', redirectTo: 'tag/:slug/licnosti', pathMatch: 'full' },
  { path: 'opstina/:slug', redirectTo: 'tag/:slug/licnosti', pathMatch: 'full' },
  { path: 'grad/:slug', redirectTo: 'tag/:slug/licnosti', pathMatch: 'full' },
];

export const routes: Route[] = [
  { path: '', pathMatch: 'full', component: WelcomePageComponent },
  ...userRoutes,
  {
    path: 'admin',
    canActivate: [adminGuard],
    loadChildren: () => import('./admin.routes').then(mod => mod.ADMIN_ROUTES),
  },
  { path: '**', redirectTo: '' },
];