<mat-toolbar class="header-toolbar fixed-toolbar">

  <!-- MENU / BACK -->
  @if (!over1200) {
  <button mat-button
    class="menu-button">
    @if(showBackButton) {
    <mat-icon (click)="onBack()">
      arrow_back_ios_new </mat-icon>
    } @else {
    <mat-icon class="menu-icon no-highlights"
      (click)="onMenu()"> menu </mat-icon>
    }
  </button>
  }

  <!-- SPACE -->
  <span class="spacer"></span>

  <!-- SEARCH -->
  <button mat-button
    class="search-button no-highlights"
    [title]="'Претрага' | translit"
    (click)="onSearch()">
    <mat-icon class="search-icon"> search </mat-icon>
  </button>

  <!-- INSTAGRAM -->
  <img class="instagram-icon"
    (click)="onInstagram()"
    [title]="'Инстаграм' | translit"
    src="assets/images/Instagram_icon.png"
    alt="instagram">

  <!-- CONTACT -->
  <button mat-button
    [title]="'Иди на контакт страницу' | translit"
    class="contact-button no-highlights"
    (click)="onContact()">
    <mat-icon class="contact-icon"> mail </mat-icon>
  </button>

  <!-- SETTINGS -->
  <button mat-button
    [title]="'Налог и подешавања' | translit"
    class="settings-button no-highlights"
    (click)="onSettings()">
    <mat-icon class="settings-icon"> settings </mat-icon>
  </button>

</mat-toolbar>