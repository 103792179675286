<div id="sidebar-wrapper">

  <div class="sidebar-content">

    @if (themeService.isLightMode()) {
    <img class="logo"
      (click)="onHome()"
      src="assets/images/gallery-logo-light-notext.png"
      alt="logo-light">
    } @else {
    <img class="logo"
      (click)="onHome()"
      src="assets/images/gallery-logo-dark-notext.png"
      alt="logo-dark">
    }

    @for (item of sidebarItems; track item; let even = $even) {
    <button mat-button
      class="sidebar-button"
      (click)="item.method()">
      <mat-icon class="item-icon"
        [class.even]="even">
        {{item.icon}} </mat-icon>
      <span class="item-text"
        [class.even]="even">
        {{item.title}}</span>
    </button>
    }

  </div>
</div>