

import { filter } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { UtilsService } from './utils.service';

@Injectable({ providedIn: 'root' })
export class PwaUpdateService {
  private snackbar = inject(MatSnackBar);
  private swUpdate = inject(SwUpdate);

  listenPwaUpdates() {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(_ => {
        this.swUpdate.activateUpdate().then(() => {
          UtilsService.openSnackbar('Ажурирање апликације...');
          console.table([
            { name: "Последње ажурирање", date: Date.now() },
          ]);
          setTimeout(() => {
            document.location.reload();
          }, 1000);
        });
      });
  }
}
