<app-header />
<app-bottom-menu />

<div class="content extra-margin-top">
  <div class="report-error">
    <mat-icon (click)="onReportIcon()">report</mat-icon>
    @if (showErrorText) {
    <span class="error-link"
      (click)="onReportText()">Пријави грешку</span>
    }
  </div>

  <div class="router-outlet">
    <router-outlet />
  </div>

</div>

<app-footer />