import { map, Observable, startWith } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, ViewChild
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { DefaultLogoDirective } from '@app/directives/default-logo.directive';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { Person, PersonsService } from '@services/persons.service';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-search-dialog',
  standalone: true,
  imports: [CommonModule, MatAutocompleteModule, MatFormFieldModule,
    DefaultLogoDirective, MatButtonModule, MatInputModule,
    ReactiveFormsModule, MatIconModule, TranslitPipe],
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchDialogComponent {
  @ViewChild("searchInput") searchInput!: ElementRef;

  private changeDetectorRef = inject(ChangeDetectorRef);
  private personsService = inject(PersonsService);
  private router = inject(Router);
  private list: any[] = [];
  private storage = 'recent-searches';
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  public control = new FormControl('');
  public recentSearches: Person[] = [];
  public filteredItems$!: Observable<any[]>;
  public showThreeLetterWarning = true;
  public showNoResultWarning = false;

  constructor(public dialogRef: MatDialogRef<SearchDialogComponent>) { }

  ngOnInit() {
    this.setListener();
    this.getItems();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
      this.changeDetectorRef.detectChanges();
    }, 300);
  }

  getItems() {
    this.personsService.getItems()
      .subscribe({
        next: response => {
          this.list = response;
          this.list.sort((a, b) => a.commonName.localeCompare(b.commonName));
          this.getRecentSearches();
        }
      });
  }

  setListener() {
    this.filteredItems$ = this.control.valueChanges.pipe(
      startWith(''),
      map(item => this.filterList(item || '')),
    );
  }

  filterList(value: string): any[] {
    const filterValue = value.toLowerCase();

    const textSlugify: string = UtilsService.slugify(filterValue);

    if (textSlugify.length < 3) {
      this.showThreeLetterWarning = true;
      this.showNoResultWarning = false;
      return [];
    }

    const result = this.list.filter(e => {
      const commonNameSlugify = UtilsService.slugify(e.commonName);
      const nameMatches = commonNameSlugify.toLowerCase().includes(textSlugify);
      if (nameMatches) {
        return true;
      }

      const fullNameSlugify = UtilsService.slugify(e.fullName);
      const fullNameMatches = fullNameSlugify.toLowerCase().includes(textSlugify);
      if (fullNameMatches) {
        return true;
      }

      const nickNameSlugify = UtilsService.slugify(e.nickName);
      const nickNameMatches = nickNameSlugify.toLowerCase().includes(textSlugify);
      if (nickNameMatches) {
        return true;
      }

      return false;
    });

    if (!result?.length) {
      this.showThreeLetterWarning = false;
      this.showNoResultWarning = true;
    }

    return result;
  }

  getRecentSearches() {
    const storageRecentSearches = localStorage.getItem(this.storage);
    if (storageRecentSearches) {
      this.recentSearches = JSON.parse(storageRecentSearches);
      this.cdr.detectChanges();
    }
  }

  addToRecentSearches(recent: Person) {
    this.recentSearches.unshift(recent);
    let uniqueList = UtilsService.removeDuplicatesByKey(this.recentSearches, 'id');
    uniqueList = uniqueList.slice(0, 5);
    const data = JSON.stringify(uniqueList);
    localStorage.setItem(this.storage, data);
  }

  onBiography(item: Person) {
    this.navigate(`biografija/${item.slug}`, item);
  }

  goToCategories() {
    this.navigate(`kategorije`);
  }

  goToTags() {
    this.navigate(`tagovi`);
  }

  goToBirthdays() {
    const path = UtilsService.getTodaySlug();
    this.navigate(`kalendar/${path}`);
  }

  navigate(path: string, item?: Person) {
    this.router.navigateByUrl(path)
      .then(() => {
        this.changeDetectorRef.detectChanges();
        this.dialogRef.close();
        if (item) {
          this.addToRecentSearches(item);
        }
      });
  }

  onClose() {
    this.dialogRef.close(true);
  }
}
