import { Observable, tap } from 'rxjs';

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

export const adminGuard: CanActivateFn = (): Observable<boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.loginAdmin$.pipe(
    tap((isLogged: boolean) => {
      if (!isLogged) {
        router.navigateByUrl('');
      }
    })
  );
};
