
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { ResponsiveService } from '@app/services/responsive.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslitPipe],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  year = (new Date).getFullYear();
  public over1200 = false;
  private responsiveService = inject(ResponsiveService);
  public changeDetectorRef = inject(ChangeDetectorRef);

  ngAfterViewInit() {
    if (!this.responsiveService.queryUnder1200) {
      return;
    }

    this.over1200 = this.responsiveService.over1200;
    this.changeDetectorRef.detectChanges();

    this.responsiveService.queryUnder1200.addListener(() => {
      this.over1200 = this.responsiveService.over1200;
      this.changeDetectorRef.detectChanges();
    });
  }
}
