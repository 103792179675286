

import { ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterOutlet } from '@angular/router';
import { ThemeService } from '@services/theme.service';
import { UtilsService } from '@services/utils.service';

import { BottomMenuComponent } from '../bottom-menu/bottom-menu.component';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { TileComponent } from '../tile/tile.component';

@Component({
  selector: 'app-drawer-content',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent,
    TileComponent, MatIconModule, BottomMenuComponent],
  templateUrl: './drawer-content.component.html',
  styleUrls: ['./drawer-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerContentComponent {
  private router = inject(Router);

  public themeService = inject(ThemeService);
  public userProfileChecked = false;
  public trackById = (index: number, item: any) => item.id;
  public showErrorText = false;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: any) {
    if (event.code === 'KeyT' && event.altKey) {
      event.stopPropagation();
      event.preventDefault();
      this.themeService.toggleTheme();
    }
  }

  onReportIcon() {
    this.showErrorText = !this.showErrorText;
  }

  onReportText() {
    this.router.navigateByUrl('kontakt#form');
    this.showErrorText = false;
  }

  onLogo() {
    UtilsService.scrollTop();
  }
}
